import React from 'react';

import Image from '../Image';
import { Link } from 'gatsby';
import { LogoWrapper } from './logo.style';

type Props = {
  style: any,
  src: string,
  title: string
}

const Logo = (props: Props) => (
  <LogoWrapper>
    <Link to="/">
      <Image src={props.src} alt={props.title} style={props.style} />
    </Link>
   </LogoWrapper>
);

export default Logo;
