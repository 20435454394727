import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { StaticQuery, graphql, withPrefix } from 'gatsby';
import websiteShareImage from '../../assets/image/website-share-image.png';

function SEO({ description, lang, meta, keywords, title, url, image, type }) {
  return (
    <StaticQuery
      query={detailsQuery}
      render={data => {
        const typeMeta = type || 'website';
        const metaDescription =
          description || data.site.siteMetadata.description;
        const metaUrl = url ? (data.site.siteMetadata.siteUrl + '/' + url) : data.site.siteMetadata.siteUrl;
        const imagePath = (image && image.childImageSharp.gatsbyImageData.images.fallback.src) || withPrefix(websiteShareImage);
        const imageShare = data.site.siteMetadata.siteUrl + imagePath;
        return (
          <Helmet
            htmlAttributes={{
              lang,
            }}
            title={title || data.site.siteMetadata.title}
            titleTemplate={title ? `%s - ${data.site.siteMetadata.title}` : data.site.siteMetadata.title}
            meta={[
              {
                name: `description`,
                content: metaDescription,
              },
              {
                property: `og:title`,
                content: title,
              },
              {
                property: `og:description`,
                content: metaDescription,
              },
              {
                property: `og:type`,
                content: typeMeta,
              },
              {
                property: `og:site_name`,
                content: 'Talent Swipe',
              },
              {
                property: `og:url`,
                content: metaUrl,
              },
              {
                property: `og:image`,
                content: imageShare,
              },
              {
                property: `twitter:image`,
                content: imageShare,
              },
              {
                property: `image`,
                content: imageShare,
              },
            ]
              .concat(
                keywords.length > 0
                  ? {
                    name: `keywords`,
                    content: keywords.join(`, `).concat([', Job', 'Career', 'Talent', 'IT', 'Software', 'Software Development', 'Technology', 'Tech'].join(', ')),
                  }
                  : []
              )
              .concat(meta)}
          />
        );
      }}
    />
  );
}

SEO.defaultProps = {
  url: 'https://talentswipe.careers',
  lang: `en`,
  meta: [],
  keywords: ['Recruitment', 'HR', 'Hiring', 'Jobs'],
};

SEO.propTypes = {
  url: PropTypes.string,
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
  image: PropTypes.object,
  type: PropTypes.string,
};

export default SEO;

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        siteUrl
      }
    }
  }
`;
