import React, { useContext } from "react";
import PropTypes from "prop-types";
import { graphql, useStaticQuery } from "gatsby";

import { Box, Container, Drawer, HamburgMenu, Logo, ScrollSpyMenu } from "@components/index";
import { DrawerContext } from "../../../contexts/DrawerContext";
import LogoImage from "../../../assets/image/logo.png";
import NavbarWrapper from '../../../components/Navbar';

const Navbar = ({ navbarStyle, logoStyle, row, menuWrapper, notMainPage }) => {
  const Data = useStaticQuery(graphql`
    query {
      dataJson {
        MENU_ITEMS {
          label
          path
          offset
        }
      }
    }
  `);

  const { state, dispatch } = useContext(DrawerContext);

  // Toggle drawer
  const toggleHandler = () => {
    dispatch({
      type: "TOGGLE"
    });
  };

  return (
    <NavbarWrapper {...navbarStyle}>
      <Container>
        <Box {...row}>
          <Logo src={LogoImage} title="Talent Swipe" style={logoStyle} />
          <Box {...menuWrapper}>
            <ScrollSpyMenu className="main_menu" menuItems={Data.dataJson.MENU_ITEMS} offset={-70} notMainPage={notMainPage} />
            <Drawer width="420px" placement="right" drawerHandler={<HamburgMenu barColor="#eb4d4b" />} open={state.isOpen} toggleHandler={toggleHandler}>
              <ScrollSpyMenu className="mobile_menu" menuItems={Data.dataJson.MENU_ITEMS} drawerClose={true} offset={-100} notMainPage={notMainPage} />
            </Drawer>
          </Box>
        </Box>
      </Container>
    </NavbarWrapper>
  );
};

Navbar.propTypes = {
  navbarStyle: PropTypes.object,
  logoStyle: PropTypes.object,
  button: PropTypes.object,
  row: PropTypes.object,
  menuWrapper: PropTypes.object,
  notMainPage: PropTypes.bool
};

Navbar.defaultProps = {
  navbarStyle: {
    className: "hosting_navbar",
    minHeight: "70px",
    display: "block"
  },
  row: {
    flexBox: true,
    alignItems: "center",
    justifyContent: ["space-between", "space-between", "space-between", "space-between"],
    width: "100%"
  },
  logoStyle: {
    maxWidth: "160px",
    marginRight: [0, 0, 0, "100px"]
  },
  button: {
    type: "button",
    fontSize: "13px",
    fontWeight: "600",
    color: "white",
    borderRadius: "4px",
    pl: "15px",
    pr: "15px",
    colors: "primaryWithBg",
    minHeight: "auto",
    height: `${1}`
  },
  menuWrapper: {
    flexBox: true,
    alignItems: "center",
  }
};

export default Navbar;
